<template>
	<div>
		<VideoIndividualPost :videoIndividualPosts="videos.individualPosts" />
		<VideoAlbums :videoAlbums="videos.albums" />
	</div>
</template>

<script>
import VideoAlbums from './VideoAlbums'
import VideoIndividualPost from './VideoIndividualPost'
export default {
	props: {
		videos: {
			type: Object,
			default: () => ({ albums: [], individualPosts: [] }),
		},
	},
	components: {
		VideoAlbums,
		VideoIndividualPost,
	},
}
</script>