<template>
	<div>
		<v-card>
			<div class="d-flex align-center justify-space-between px-4">
				<v-card-title>Videos</v-card-title>
				<v-btn
					color="var(--blue)"
					dark
					class="px-4 text-capitalize"
					@click="createPost()"
				>
					Upload Video
				</v-btn>
			</div>
			<v-divider></v-divider>
			<v-card height="400px" class="scroll mb-0">
				<v-row class="mx-0 d-flex" v-if="posts.length > 0">
					<v-col
						cols="4"
						v-for="(post, index) in posts"
						:key="index"
						class="d-flex"
					>
						<v-card class="mb-0" v-if="post.videoLink !== null">
							<img
								:src="imageLink(post.videoLink)"
								class="videoBanner"
							/>
							<div
								class="
									d-flex
									flex-row
									justify-space-between
									align-baseline
								"
							>
								<v-card-text class="body-1 black--text py-2">
									{{ post.caption }}
								</v-card-text>

								<v-menu class="actions-dropdown">
									<template #activator="{ on: onMenu }">
										<v-tooltip bottom>
											<template
												#activator="{ on: onTooltip }"
											>
												<v-btn
													icon
													v-on="{
														...onMenu,
														...onTooltip,
													}"
													class="actions"
													><span
														class="
															mdi
															mdi-dots-vertical
														"
													></span
												></v-btn>
											</template>

											<span>Other Actions</span>
										</v-tooltip>
									</template>

									<v-list>
										<v-list-item>
											<v-list-item-title
												class="options"
												@click="removePost(index)"
												>Delete</v-list-item-title
											>
										</v-list-item>
										<v-list-item>
											<v-list-item-title
												class="options"
												@click="editPost(post, index)"
												>Edit</v-list-item-title
											>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</v-card>
		</v-card>
		<VideoCreateIndividualDialog
			v-if="showPostDialog"
			@on-post-update="updatePost($event)"
			@close-dialog="closeDialog($event)"
			:post="selectedPost"
		/>
	</div>
</template>

<script>
import VideoCreateIndividualDialog from './VideoCreateIndividualDialog'
export default {
	props: {
		videoIndividualPosts: {
			type: Array,
			required: true,
			default: () => [],
		},
	},
	data() {
		return {
			posts: [],
			selectedPost: null,
			index: null,
			showPostDialog: false,
		}
	},
	created() {
		this.posts = this.videoIndividualPosts
	},
	components: {
		VideoCreateIndividualDialog,
	},
	methods: {
		editPost(post, ind) {
			this.showPostDialog = !this.showPostDialog
			this.selectedPost = post
			this.index = ind
		},
		createPost() {
			this.showPostDialog = !this.showPostDialog
		},
		removePost(index) {
			let posts = this.posts
			posts.splice(index, 1)
			this.posts = posts
			this.savePost()
		},
		closeDialog(emittedObj) {
			this.showPostDialog = !this.showPostDialog
			this.$emit('close-dialog', emittedObj)
		},
		updatePost(emittedObj) {
			if (this.index != null && this.selectedPost) {
				//Existing
				this.posts[this.index] = emittedObj
			} else {
				//New
				this.posts.push(emittedObj)
			}
			this.savePost()
			this.selectedPost = null
			this.index = null
			this.showPostDialog = false
			//request
		},
		imageLink(link) {
			let videoLink = link.split('/')
			return `https://img.youtube.com/vi/${videoLink[videoLink.length-1]}/0.jpg`
		},
		savePost() {
			const projectId = this.$store.state.app.projectId
			let payload = {
				videos: {
					individualPosts: this.posts,
				},
			}
			this.axios({
				method: 'patch',
				url: `/${projectId}/pages/gallery`,
				data: payload,
			}).then((res) => {
				this.posts = res.data.data.meta.videos.individualPosts
			}).catch(error=>{
					const { data } = error.response
					if (data.statusCode == 400) {
						this.isSaving=false
						this.$snackbar.notify({
							message:data.message,
							color: 'black',
						})
					}
			})
		},
	},
}
</script>

<style scoped>
.videoBanner {
	width: 12rem;
	height: 12rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
.mdi-dots-vertical {
	font-size: 1.5rem;
}
.scroll {
	overflow-y: scroll;
}
</style>