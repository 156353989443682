<template>
	<v-navigation-drawer
		v-model="drawer"
		fixed
		stateless
		temporary
		right
		class="album-drawer"
	>
		<div class="d-flex align-center header pt-2 px-2 pb-2">
			<span
				class="mdi mdi-chevron-left back-icon"
				@click="clear()"
			></span>
			<h1 class="crud-title ml-2">Album</h1>
			<span class="mdi mdi-close back-icon" @click="clear()"></span>
		</div>
		<div class="pa-4">
			<h1 class="title mb-1">Title</h1>
			<v-text-field
				flat
				dense
				placeholder="Enter the title for the Album"
				outlined
				persistent-hint
				:error-messages="errors.title"
				v-model="title"
			></v-text-field>
			<h1 class="title mb-1">Description</h1>
			<v-text-field
				solo
				flat
				dense
				placeholder="Enter the description for the Album"
				outlined
				persistent-hint
				:error-messages="errors.description"
				v-model="description"
			></v-text-field>
			<div class="d-flex align-center justify-space-between px-4 mb-2">
				<h1 class="title mb-1">Content</h1>
				<v-btn
					color="var(--blue)"
					dark
					class="px-4 text-capitalize"
					@click="createPost()"
				>
					Add Videos
				</v-btn>
			</div>
			<VideoCreateIndividualDialog
				v-if="showPostDialog"
				@on-post-update="updatePost($event)"
				@close-dialog="closeDialog($event)"
				:post="selectedPost"
			/>

			<v-card height="350px" class="scroll mb-0">
				<v-row class="mx-0 d-flex" v-if="posts.length > 0">
					<v-col
						cols="4"
						v-for="(post, index) in posts"
						:key="index"
						class="d-flex"
					>
						<v-card class="mb-0" v-if="post.videoLink !== null">
							<div
								v-html="post.videoLink"
								class="videoBanner"
							></div>

							<div
								class="
									d-flex
									flex-row
									justify-space-between
									align-baseline
								"
							>
								<v-card-text class="body-1 black--text py-2">
									{{ post.caption }}
								</v-card-text>

								<v-menu class="actions-dropdown">
									<template #activator="{ on: onMenu }">
										<v-tooltip bottom>
											<template
												#activator="{ on: onTooltip }"
											>
												<v-btn
													icon
													v-on="{
														...onMenu,
														...onTooltip,
													}"
													class="actions"
													><span
														class="
															mdi
															mdi-dots-vertical
														"
													></span
												></v-btn>
											</template>

											<span>Other Actions</span>
										</v-tooltip>
									</template>

									<v-list>
										<v-list-item>
											<v-list-item-title
												class="options"
												@click="removePost(index)"
												>Delete</v-list-item-title
											>
										</v-list-item>
										<v-list-item>
											<v-list-item-title
												class="options"
												@click="editPost(post, index)"
												>Edit</v-list-item-title
											>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</v-card>
			<v-footer class="footer" absolute>
				<v-btn
					color="var(--blue)"
					dark
					class="px-4 text-capitalize mx-auto"
					:disabled="isDisabled()"
					@click="saveAlbum()"
				>
					Save Album
				</v-btn></v-footer
			>
		</div>
	</v-navigation-drawer>
</template>

<script>
import VideoCreateIndividualDialog from './VideoCreateIndividualDialog'
export default {
	props: { album: { type: Object, default: () => ({}) } },
	data() {
		return {
			drawer: true,
			title: null,
			description: null,
			showPostDialog: false,
			selectedPost: null,
			index: null,
			posts: [],
			errors: {
				title: '',
				description: '',
				posts: '',
			},
		}
	},
	components: {
		VideoCreateIndividualDialog,
	},
	watch: {
		title() {
			return (this.errors.title = '')
		},
		description() {
			return (this.errors.description = '')
		},
		posts() {
			return (this.errors.posts = '')
		},
	},
	created() {
		if (this.album && Object.keys(this.album).length > 0) {
			this.title = this.album.title
			this.description = this.album.description
			this.posts = this.album.posts
		}
	},
	methods: {
		createPost() {
			this.showPostDialog = !this.showPostDialog
		},
		editPost(post, ind) {
			this.showPostDialog = !this.showPostDialog
			this.selectedPost = post
			this.index = ind
		},
		removePost(index) {
			let posts = this.posts
			posts.splice(index, 1)
			this.posts = posts
		},
		closeDialog() {
			this.showPostDialog = !this.showPostDialog
		},
		isDisabled() {
			if (
				this.title === '' ||
				this.description === '' ||
				this.posts.length <= 0
			) {
				return true
			}
			return false
		},
		updatePost(emittedObj) {
			if (this.index != null && this.selectedPost) {
				//Existing
				this.posts[this.index] = emittedObj
			} else {
				//New
				this.posts.push(emittedObj)
			}
			this.selectedPost = null
			this.index = null
			this.showPostDialog = false
		},
		clear() {
			this.$emit('close-drawer')
		},
		saveAlbum() {
			let isValid = true
			if (this.title === null) {
				this.errors.title = 'Please add title for album'
				isValid = false
			}
			if (this.description === null) {
				this.errors.description = 'Please add description for album'
				isValid = false
			}
			if (this.posts.length <= 0) {
				this.errors.posts = 'Please add post for album'
				isValid = false
			}
			if (isValid) {
				let album = {
					title: this.title,
					description: this.description,
					posts: this.posts,
				}
				this.$emit('on-album-update', album)
			}
		},
	},
}
</script>

<style scoped>
.v-text-field.v-text-field--enclosed .v-text-field__details {
	margin-bottom: 0px;
}
.album-drawer {
	width: 50% !important;
}
.scroll {
	overflow-y: scroll;
}
.videoBanner >>> iframe {
	width: 12.9rem;
	height: 12rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
.mdi-dots-vertical {
	font-size: 1.5rem;
}
.crud-title {
	width: 100% !important;
}
.back-icon {
	font-size: 25px;
	cursor: pointer;
}
</style>
