<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" persistent max-width="900px">
			<v-card class="px-8 py-4 mb-0">
				<h1 class="display-1 mb-2 text-center">Youtube Video</h1>
				<p class="heading mb-1">Video Link</p>
				<v-text-field
					solo
					flat
					dense
					placeholder="Enter the embedded link for video"
					outlined
					:error-messages="errors.videoLink"
					v-model="videoLink"
				></v-text-field>
				<div v-html="videoLink" v-if="videoLink"></div>
				<p class="heading mb-1">Caption</p>
				<v-text-field
					solo
					flat
					dense
					placeholder="Enter the caption for video"
					outlined
					:error-messages="errors.caption"
					v-model="caption"
				></v-text-field>
				<v-card-actions class="btn-action">
					<v-btn @click="clear()" depressed class="save">Close</v-btn>
					<v-btn @click="saveContent()" depressed class="save"
						>Save</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>
<script>
export default {
	props: {
		post: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			videoLink: null,
			caption: null,
			dialog: true,
			errors: {
				videoLink: '',
				caption: '',
			},
		}
	},
	created() {
		if (this.post && Object.keys(this.post).length > 0) {
			this.videoLink = this.post.videoLink
			this.caption = this.post.caption
		}
	},
	watch: {
		videoLink() {
			return (this.errors.videoLink = '')
		},
	},
	methods: {
		clear() {
			this.$emit('close-dialog')
		},
		saveContent() {
			let isValid = false
			if (this.videoLink !== null) {
				isValid = true
			} else {
				this.errors.videoLink = 'Please Enter Video Link'
			}
			if (isValid) {
				let video = {
					videoLink: this.videoLink,
					caption: this.caption,
				}
				this.$emit('on-post-update', video)
			}
		},
	},
}
</script>
<style scoped>
.v-tabs {
	width: 80%;
	margin: 6.2rem auto;
}
.btn-action {
	display: flex;
	justify-content: flex-end;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	text-transform: capitalize;
	padding: 10px 8px;
}
</style>